export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
  },
  appManager: {
    main: {
      container: 'data-testid app-mgr-main-container',
      alert: 'data-testid app-mgr-main-alert',
      teamselect: {
        loading: 'data-testid app-mgr-main-teamselect-loading',
        select: 'data-testid app-mgr-main-teamselect-select'
      },
      form: {
        container: 'data-testid app-mgr-main-form-container',
        language: 'data-testid app-mgr main-form-language',
        loading: 'data-testid app-mgr main-form-loading',
        plugin: {
          toggle: 'data-testid app-mgr main-form-plugin-toggle'
        },
        submit: {
          container: 'data-testid app-mgr main-form-submit-container',
          button: 'data-testid app-mgr main-form-submit-button',
          clicked: 'data-testid app-mgr main-form-submit-clicked',
        },
      },
      db: {
        button: 'data-testid app-mgr main-dashboard-button',
        panel: 'data-testid app-mgr main-dashboard-panel',
        loading: 'data-testid app-mgr main-dashboard-loading',
        container: 'data-testid app-mgr main-dashboard-container',
        row: {
          card: 'data-testid app-mgr main-dashboard-row-card',
          install: 'data-testid app-mgr main-dashboard-row-install',
          uninstall: 'data-testid app-mgr main-dashboard-row-uninstall',
          upgrade: 'data-testid app-mgr main-dashboard-row-upgrade',
          downgrade: 'data-testid app-mgr main-dashboard-row-downgrade',
        },
        batch: {
          install: 'data-testid app-mgr main-dashboard-batch-install',
          upgrade: 'data-testid app-mgr main-dashboard-batch-upgrade',
          uninstall: 'data-testid app-mgr main-dashboard-batch-uninstall',
        },
        confirm: 'data-testid app-mgr main-dashboard-confirm',
        modal: 'data-testid app-mgr main-dashboard-modal',
      }
    },
  }
};
