import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppManager } from '../pages/AppManager';
import { useNavigation } from '../../utils/utils.routing';

export const Routes = () => {
  useNavigation();

  return (
    <Switch>
      {/* Default page */}
      <Route component={AppManager} />
    </Switch>
  );
};
