// External
import React, { useContext, useEffect, useState } from 'react';
import { CollapsableSection, LoadingPlaceholder } from '@grafana/ui';

// Internal
import { ManagerContext } from './ManagerContext';
import { GET_TEAM_DATA_URL, SET_TEAM_DATA_URL } from 'utils/utils.endpoints';
import { FetchError } from 'utils/utils.errors';
import { Team } from 'utils/utils.types';
import { PluginRow } from './PluginRow';
import { testIds } from 'components/testIds';

export function PluginContainer() {
    const [pluginsLoading, setPluginsLoading] = useState<boolean>(true);
    const [busy, setBusy] = useState<boolean>(false);

    const { setFormErr, setFormState, formState, dataState } = useContext(ManagerContext);
    const { team } = formState;
    const teamName = team?.name;

    const { plugins } = dataState;

    useEffect(() => {
        // Set initial state
        setPluginsLoading(true);

        // Fetch team plugins
        fetchTeamPlugins();
        // Will only run on mount or when the team is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamName]);

    /**
     * Fetches the teams plugins.
     */
    const fetchTeamPlugins = async () => {
        try {
            if (teamName) {
                const url = GET_TEAM_DATA_URL(teamName);
                const res = await fetch(url);
                if (res.ok) {
                    const data = await res.json();
                    setFormState({
                        ...formState,
                        plugins: data.apps
                    });
                    setPluginsLoading(false);
                }
                // If provisioner does not have the team in source
                else if (res.status === 404) {
                    const teamData = await setNewTeam(team);
                    setFormState({
                        ...formState,
                        plugins: teamData.apps
                    });
                    setPluginsLoading(false);
                }
                else {
                    throw new FetchError(res, url);
                }

            } else {
                throw new Error('Team name is not set.');
            }
        } catch (err: any) {
            setFormErr(err.message);
        }
    }

    /**
     * Sets a new team's data in the provisioner backend source.
     * @param team the team.
     * @returns the team.
     */
    const setNewTeam = async (team: Team) => {
        const teamBody = {
            name: team.name,
            apps: [],
            languages: team.orgs.map(o => o.language.BCP47)
        };

        const url = SET_TEAM_DATA_URL(team.name);
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(teamBody)
        });

        if (!res.ok) {
            throw new FetchError(res, url);
        }

        return teamBody;
    }

    return (
        <>
            {pluginsLoading
                ?
                <LoadingPlaceholder text='...Loading Plugins' data-testid={testIds.appManager.main.form.loading} />
                :
                <CollapsableSection label="WideSky App Plugins" isOpen>
                    {plugins && plugins.map(plugin => <PluginRow
                        key={plugin.id}
                        plugin={plugin}
                        provisioned={formState.plugins.includes(plugin.id)}
                        busy={busy}
                        setBusy={setBusy}
                    />)}
                </CollapsableSection>
            }
        </>
    );
}
