// External
import React, { useContext, useState } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

// Internal
import { ManagerContext } from './ManagerContext';
import { CREATE_ORG_URL, CREATE_TEAM_URL, DELETE_TEAM_URL, fetchPluginManifest, GET_TEAM_PERMISSIONS_URL, SET_TEAM_DATA_URL, switchOrgContext, TEAM_PERMISSIONS_URL } from 'utils/utils.endpoints';
import { FetchError } from 'utils/utils.errors';
import { Dashboard, Language, Org, PermissionRecord, Team } from 'utils/utils.types';
import { ADMIN_ORG_ID, DEFAULT_LANGUAGE } from 'utils/utils.constants';
import { testIds } from 'components/testIds';

export function SubmitButton() {
    const style = useStyles2(getStyles);
    const [saving, setSaving] = useState<boolean>(false);

    const { formState, setFormState, dataState, setDataState, setFormErr } = useContext(ManagerContext);
    const orgs = dataState.orgs;
    const primaryOrg = orgs.find(o => o.primary);

    const { team, plugins, languages } = formState;

    /**
     * Performs operation for submitting the form.
     */
    const submitForm = async () => {
        if (!team) {
            return;
        }

        setSaving(true);

        // Provision teams based on selected languages
        const oldLang = team.orgs.map(o => o.language);

        const added = languages.filter(n => !oldLang.some(o => o.BCP47 === n.BCP47));
        const removed = oldLang.filter(o => !languages.some(n => n.BCP47 === o.BCP47));

        try {
            // Create teams (and orgs) depending on state
            await createTeams(team, added);
            await deleteTeams(team, removed);

            // Save team state to provisioner backend source
            await saveToSource(team, plugins);

            // Update Grafana to the new settings
            await updateGrafana();
        } catch (err: any) {
            setFormErr(err.message);
        }

        // set saved states.
        setSaving(false);

        // Update form and data states
        const teams = dataState.teams;
        const updatedTeams = teams.filter(t => t.name !== team.name);
        updatedTeams.push(team);

        setFormState({
            ...formState,
            changed: false
        });
        setDataState({
            ...dataState,
            teams: updatedTeams,
            orgs: orgs
        });
    }

    /**
     * Creates teams if new languages are provisioned for the team.
     * @param team the team.
     * @param languages an array of added languages.
     */
    const createTeams = async (team: Team, languages: Language[]) => {
        for (const idx in languages) {
            const lang = languages[idx];
            let org = orgs.find(o => o.language.BCP47 === lang.BCP47);
            if (!org) {
                org = await createOrg(lang);
            }
            await switchOrgContext(org.id);

            const newTeam = {
                name: team.name,
                email: team.email
            };

            const res = await fetch(CREATE_TEAM_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newTeam)
            });
            if (!res.ok) {
                throw new FetchError(res, CREATE_TEAM_URL);
            }

            const data = await res.json();

            // Push to local data state
            team.orgs.push({
                orgId: org.id,
                language: org.language,
                teamId: data.teamId
            });
        }

        // Reset org context to admin context
        await switchOrgContext(ADMIN_ORG_ID);
    }

    /**
     * Deletes teams if languages are removed for the team.
     * @param team the team.
     * @param languages an array of removed languages.
     */
    const deleteTeams = async (team: Team, languages: Language[]) => {
        for (const idx in languages) {
            const lang = languages[idx];
            const org = team.orgs.find(o => o.language.BCP47 === lang.BCP47);
            if (org) {
                await switchOrgContext(org.orgId);

                const url = DELETE_TEAM_URL(org.teamId);
                const res = await fetch(url, {
                    method: 'DELETE'
                });
                if (!res.ok) {
                    throw new FetchError(res, url);
                }

                // Update local data state
                team.orgs = team.orgs.filter(o => o.orgId !== org.orgId);
            }
        }

        // Reset org context to admin context
        await switchOrgContext(ADMIN_ORG_ID);
    }

    /**
     * Creates new organisation if new language is provisioned and
     * the organisation does not exist.
     * @param language the BCP-47 language code.
     * @returns an organisation.
     */
    const createOrg = async (language: Language) => {
        const orgName = language.orgAlias ?
                            // Additional languages, e.g. French
                            `${primaryOrg?.name}_${language.orgAlias}` :
                            // Usually the main default English org
                            primaryOrg?.name;

        const res = await fetch(CREATE_ORG_URL, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                name: orgName
            })
        });
        if (!res.ok) {
            throw new FetchError(res, CREATE_ORG_URL);
        }

        const data = await res.json();
        const org: Org = {
            id: data.orgId,
            name: orgName!,
            language: {
                full: language.full,
                BCP47: language.BCP47,
                orgAlias: language.orgAlias || '',
            },
            primary: false
        };

        // Push to local data state
        orgs.push(org);

        return org;
    }

    /**
     * Save the current form state to provisioner source
     */
    const saveToSource = async (team: Team, apps: string[]) => {
        const formBody = {
            name: team.name,
            apps: apps,
            languages: languages.map(l => l.BCP47)
        };

        const url = SET_TEAM_DATA_URL(team.name);
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formBody)
        });

        if (!res.ok) {
            throw new FetchError(res, url);
        }
    }

    /**
     * Retrieves the current set of permissions for a given team.
     * @param {number} teamId - The team id whose permissions are being fetched.
     * @returns {Promise<PermissionRecord[]>} A promise that resolves to an array of PermissionRecords.
     * @throws {FetchError} Throws an error if the network request is unsuccessful.
     */
    const getCurrentPermissions = async (teamId: number): Promise<PermissionRecord[]> => {
        const url = GET_TEAM_PERMISSIONS_URL(teamId);
        const res = await fetch(url);
        if (!res.ok) {
            throw new FetchError(res, url);
        }
        const data = await res.json();
        return data;
    }

    /**
     * Fetches the permissions selected based on the provided locale.
     * @param {string} locale - The locale used to determine which permissions are relevant.
     * @returns {Promise<PermissionRecord[]>} A promise that resolves to an array of PermissionRecords.
     */
    const getSelectedPermissions = async (locale: string): Promise<PermissionRecord[]> => {
        const selectedPermissions = plugins.map(async (pluginId) => {
            const pluginManifest = await fetchPluginManifest(pluginId);

            const dashboards = pluginManifest.dashboards;
            const selectedLocale = dashboards[locale] ? locale : DEFAULT_LANGUAGE.BCP47;

            const pageAccess = dashboards[selectedLocale].map((dashboard: Dashboard) => dashboard.name);

            const permission: PermissionRecord = {
                pageAccess, 
                pluginId, 
                lastModified: Date.now(),
                permId: -1
            };

            return permission; 
        });

        return Promise.all(selectedPermissions);
    };

    /**
     * Updates a permission record identified by its permId.
     * @param {number} permId - The permission id to update.
     * @param {string[]} pageAccess - An array of strings representing the pages to which access is permitted.
     * @param {number} lastModified - A timestamp representing the last modification time.
     * @throws {FetchError} Throws an error if the network request is unsuccessful.
     */
    const updatePermission = async (permId: number, pageAccess: string[], lastModified: number) => {
        const formBody = {
            permId,
            pageAccess,
            lastModified
        };

        const url = TEAM_PERMISSIONS_URL;
        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formBody)
        })

        if (!res.ok) {
            throw new FetchError(res, url);
        }
    }

    /**
     * Removes a specific permission record identified by its permId.
     * @param {number} permId - The permission id to remove.
     * @throws {FetchError} Throws an error if the network request is unsuccessful.
     */
    const removePermission = async (permId: number) => {
        const formBody = {
            permId
        };

        const url = TEAM_PERMISSIONS_URL;
        const res = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formBody)
        });

        if (!res.ok) {
            throw new FetchError(res, url);
        }
    }

    /**
     * Adds a new plugin permission record for a given team, with specified page access.
     * @param {number} teamId - Team id to which the permission is being added.
     * @param {string} pluginId - Plugin id to be added.
     * @param {string[]} pageAccess - An array of strings representing the pages to which access is permitted.
     * @throws {FetchError} Throws an error if the network request is unsuccessful.
     */
    const addPermission = async (teamId: number, pluginId: string, pageAccess: string[]) => {
        const formBody = {
            teamId,
            pluginId,
            pageAccess
        };

        const url = TEAM_PERMISSIONS_URL;
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formBody)
        });

        if (!res.ok) {
            throw new FetchError(res, url);
        }
    }
 
    /**
     * Updates the Grafana permissions based on the selected permissions for each organization within a team.
     * This function performs the following actions for each organization in the team:
     * 1. Switches the organization context to the current organization.
     * 2. Retrieves the current permissions for the organization's team.
     * 3. Fetches the selected permissions based on the organization's language setting.
     * 4. For each current permission:
     *    a. If it's not found in the selected permissions, it's removed.
     *    b. If it's found, it's updated with the selected permission's details.
     * 5. Any remaining selected permissions (i.e., those not present in the current permissions) are added.
     * 6. Finally, switches the organization context back to the admin organization.
     * 
     * Note: This function assumes that a team exists and will not execute if `team` is not defined.
     */
    const updateGrafana = async () => {
        if (!team) {
            return;
        }

        for (const org of team.orgs) {
            await switchOrgContext(org.orgId);

            const currentPermissions = await getCurrentPermissions(org.teamId);
            const selectedPermissions = await getSelectedPermissions(org.language.BCP47);

            currentPermissions.forEach(currentPermission => {
                const updatePermissionIndex = selectedPermissions.findIndex(selectedPermission =>
                    selectedPermission.pluginId === currentPermission.pluginId);

                if (updatePermissionIndex === -1) {
                    removePermission(currentPermission.permId);
                    return;
                }

                updatePermission(currentPermission.permId, 
                    selectedPermissions[updatePermissionIndex].pageAccess, 
                    selectedPermissions[updatePermissionIndex].lastModified);

                selectedPermissions.splice(updatePermissionIndex, 1);
            });

            selectedPermissions.forEach(permission => {
                addPermission(org.teamId, permission.pluginId, permission.pageAccess);
            });
        }

        await switchOrgContext(ADMIN_ORG_ID);
    }

    return (
        <div className={style.marginTop} data-testid={testIds.appManager.main.form.submit.container}>
            {!saving
                ?
                (
                    <Button
                        data-testid={testIds.appManager.main.form.submit.button}
                        onClick={async () => {
                            await submitForm();
                        }}>
                        Save Changes
                    </Button>
                )
                :
                (
                    <Button
                        data-testid={testIds.appManager.main.form.submit.clicked}
                        disabled>
                        Saving...
                    </Button>
                )
            }
        </div>
    );
}

const getStyles = (theme: GrafanaTheme2) => ({
    marginTop: css`
    margin-top: ${theme.spacing(2)};
    `
});
