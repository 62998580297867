// External
import React from 'react';

// Internal
import { EmptyDataState, EmptyFormState, IManagerContext } from 'utils/utils.types';

export const ManagerContext = React.createContext<IManagerContext>({
    dataState: EmptyDataState,
    setDataState: () => {},
    formState: EmptyFormState,
    setFormState: () => {},
    formErr: undefined,
    setFormErr: () => {}
});
