// External
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { Button, HorizontalGroup } from '@grafana/ui';

// Internal
import { Plugin } from 'utils/utils.types';
import { ACTIONS, FuncMap } from 'utils/utils.dbActions';
import { switchOrgContext } from 'utils/utils.endpoints';
import { ADMIN_ORG_ID } from 'utils/utils.constants';
import { ConfirmModal } from '../ConfirmModal';
import { testIds } from 'components/testIds';

interface BBProps {
    sorted: any[]
    setSorted: React.Dispatch<React.SetStateAction<any[]>>,
    plugin: Plugin,
    setDashboardError: React.Dispatch<React.SetStateAction<string | undefined>>,
    busy: boolean,
    setBusy: React.Dispatch<React.SetStateAction<boolean>>
}

interface ConfirmProps {
    modalOpen: boolean,
    action: string,
    dashboards: any[]
}

const emptyConfirm: ConfirmProps = {
    modalOpen: false,
    action: "",
    dashboards: []
}

export function BatchButtons(props: BBProps) {
    const { sorted, setSorted, plugin, setDashboardError, busy, setBusy } = props;

    const [install, setInstall] = useState<any[]>([]);
    const [upgrade, setUpgrade] = useState<any[]>([]);
    const [uninstall, setUninstall] = useState<any[]>([]);
    const [rowState, setRowState] = useState<any>({
        [ACTIONS.Install]: false,
        [ACTIONS.Upgrade]: false,
        [ACTIONS.Uninstall]: false,
    });
    const [confirm, setConfirm] = useState<any>(emptyConfirm);


    useEffect(() => {
        // Set states
        setInstall(sorted.filter((e: any) => e.actions.includes(ACTIONS.Install)));
        setUpgrade(sorted.filter((e: any) => e.actions.includes(ACTIONS.Upgrade)));
        setUninstall(sorted.filter((e: any) => e.actions.includes(ACTIONS.Uninstall)));

        // Will only run on mount or when sorted dashboards change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorted]);

    /**
     * Executes the given operation.
     * @param opType The type of operation.
     * @param dashboard the dashboard.
     * @param tempSorted a copy of the sorted dashboards.
     */
    const executeOp = async (opType: ACTIONS, dashboard: any, tempSorted: any[]) => {
        try {
            // For each dashboard in variants
            const newVariants = [];
            for (const idx in dashboard.variants) {
                const db = dashboard.variants[idx];

                if (db.actions.includes(opType)) {
                    // Execute operation
                    const variant = await FuncMap[opType]({ ...db, uid: dashboard.uid }, plugin);
                    newVariants.push(variant);
                }
            }

            // Return to admin context after finish
            await switchOrgContext(ADMIN_ORG_ID);

            // Replace with updated actions and folders
            const idx = tempSorted.findIndex((db: any) => db.uid === dashboard.uid);
            tempSorted[idx] = {
                ...dashboard,
                variants: newVariants,
                actions: dashboard.actions.filter((a: string) => a !== opType)
            };

            // Push new available action
            if (opType === ACTIONS.Install) {
                tempSorted[idx].actions.push(ACTIONS.Uninstall);
            }
            // Only install available on uninstall
            else if (opType === ACTIONS.Uninstall) {
                tempSorted[idx].actions = [ACTIONS.Install];
            }

        } catch (err: any) {
            setDashboardError(err.message);
        }
    }

    /**
     * Execute operations on a batch of dashboards.
     * @param opType the operation type.
     * @param dashboards an array of the dashbaords.
     */
    const batchExecute = async (opType: ACTIONS, dashboards: any[]) => {
        // Set loading
        setBusy(true);
        setRowState({
            ...rowState,
            [opType]: true
        });

        const tempSorted = [...sorted];

        for (const idx in dashboards) {
            const dashboard = dashboards[idx];
            await executeOp(opType, dashboard, tempSorted);
        }
        setConfirm(emptyConfirm);
        setSorted(tempSorted);

        // Set state
        setBusy(false);
        setRowState({
            ...rowState,
            [opType]: false
        });
    }

    return (
        <>
            <HorizontalGroup spacing='sm'>
                {install.length > 0 &&
                    <Button
                        data-testid={testIds.appManager.main.db.batch.install}
                        variant='primary'
                        disabled={rowState[ACTIONS.Install] || busy}
                        onClick={() => { batchExecute(ACTIONS.Install, install); }}
                    >
                        {rowState[ACTIONS.Install] ? "Installing..." : "Install All"}
                    </Button>}
                {upgrade.length > 0 &&
                    <Button
                        data-testid={testIds.appManager.main.db.batch.upgrade}
                        variant='success'
                        disabled={rowState[ACTIONS.Upgrade] || busy}
                        onClick={() => {
                            setConfirm({
                                modalOpen: true,
                                action: ACTIONS.Upgrade,
                                dashboards: upgrade
                            });
                        }}
                    >
                        {rowState[ACTIONS.Upgrade] ? "Upgrading..." : "Upgrade All"}
                    </Button>}
                {uninstall.length > 0 &&
                    <Button
                        data-testid={testIds.appManager.main.db.batch.uninstall}
                        variant='destructive'
                        className={style.destructiveBtn}
                        disabled={rowState[ACTIONS.Uninstall] || busy}
                        onClick={() => {
                            setConfirm({
                                modalOpen: true,
                                action: ACTIONS.Uninstall,
                                dashboards: uninstall
                            });
                        }}
                    >
                        {rowState[ACTIONS.Uninstall] ? "Uninstall..." : "Uninstall All"}
                    </Button>}
            </HorizontalGroup>
            {
                confirm.modalOpen &&
                <ConfirmModal
                    isOpen={confirm.modalOpen}
                    title={`${confirm.action} All Dashboards`}
                    body={`Are you sure? All user changes will be lost after this action!`}
                    onConfirm={() => { batchExecute(confirm.action, confirm.dashboards) }}
                    onDismiss={() => { setConfirm(emptyConfirm) }}
                />
            }
        </>
    );
}

const style = {
    destructiveBtn: css`
        margin-top: 2px;
    `
}
