// External
import React from 'react';
import { Button } from '@grafana/ui';

// Internal
import { ACTIONS } from 'utils/utils.dbActions';
import { testIds } from 'components/testIds';

interface NBProps {
    rowState: any,
    executeOp: (opType: ACTIONS) => Promise<void>,
    busy: boolean
}

export function InstallButton(props: NBProps) {
    const { rowState, executeOp, busy } = props;

    return (
        <Button
        data-testid={testIds.appManager.main.db.row.install}
            disabled={rowState[ACTIONS.Install] || busy}
            variant='primary'
            onClick={async () => { await executeOp(ACTIONS.Install); }}
        >
            {rowState[ACTIONS.Install] ? "Installing..." : ACTIONS.Install}
        </Button>
    );
}
