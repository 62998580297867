// External
import React from 'react';
import { Button, InlineField } from '@grafana/ui';

// Internal
import { testIds } from 'components/testIds';

interface DMProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function DashboardButton(props: DMProps) {
    const { open, setOpen } = props;

    return (
        <>
            <InlineField>
                <Button
                    data-testid={testIds.appManager.main.db.button}
                    size='md'
                    variant={open ? 'secondary' : 'primary'}
                    onClick={() => setOpen(!open)}>
                    {open ? "Hide Dashboards" : "Show Dashboards"}
                </Button>
            </InlineField>
        </>
    );
}

