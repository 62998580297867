// Imports
import ISO6391 from 'iso-639-1';
import tags from 'language-tags';

export type IManagerContext = {
    dataState: DataState,
    setDataState: React.Dispatch<React.SetStateAction<DataState>>,
    formState: FormState,
    setFormState: React.Dispatch<React.SetStateAction<FormState>>,
    formErr: string | undefined,
    setFormErr: React.Dispatch<React.SetStateAction<string | undefined>>
};

export interface DataState {
    orgs: Org[],
    teams: Team[]
    languages: Language[],
    plugins: any[],
    config: Config
};

export const EmptyDataState: DataState = {
    orgs: [],
    teams: [],
    languages: [],
    plugins: [],
    config: {
        primaryOrgId: -1
    }
};

export interface FormState {
    team: Team | undefined,
    languages: Language[],
    plugins: string[],
    changed: boolean,
};

export const EmptyFormState: FormState = {
    team: undefined,
    languages: [],
    plugins: [],
    changed: false
};

export interface Org {
    id: number,
    name: string,
    language: Language,
    primary: boolean,
    orgAlias?: string,
};

export interface Team {
    name: string,
    email: string,
    orgs: TeamOrgRef[],
};

export interface Language {
    BCP47: string,
    full: string,
    orgAlias: string,
};
export interface Plugin {
    name: string,
    id: string,
    version: string,
    updated: string,
    author: string,
    description: string,
    overrideLabel: {
        [key: string]: string;
    };
};
export interface Config {
    primaryOrgId: number
};

export interface PluginManifest {
    version: string;
    pluginId: string;
    dashboards: {
        [locale: string]: Dashboard[];
    };
};

export interface Dashboard {
    uid: string;
    path: string;
    name: string;
    wsProvisionerVersion: number;
};

export interface PermissionRecord {
    permId: number;
    pluginId: string;
    lastModified: number;
    pageAccess: string[];
};

/**
 * Attempts to parse the language string and will
 * return a human readable string, else an empty string.
 * @param language a BTF-47 language string
 */
export const ParseLanguage = (language: string) => {
    let languageString = "";
    if (tags(language).valid()) {
        const codes = language.split('-');
        languageString = ISO6391.getName(codes[0]);

        // Append region if it exists
        if (codes.length > 1) {
            languageString += ` (${codes[1]})`;
        }
    }
    return languageString;
}

export interface TeamOrgRef {
    orgId: number,
    language: Language,
    teamId: number,
};
