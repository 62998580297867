// External
import React from 'react';
import { css } from '@emotion/css';
import { Button, HorizontalGroup, Modal, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

// Internal
import { testIds } from 'components/testIds';

interface CMProps {
    title: string,
    isOpen: boolean,
    onDismiss: () => void,
    body: string
    onConfirm: () => void
}

export function ConfirmModal(props: CMProps) {
    const { title, isOpen, onDismiss, body, onConfirm } = props;

    const styles = useStyles2(getStyles);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onDismiss={onDismiss}
            closeOnEscape={false}
            closeOnBackdropClick={false}
            trapFocus={true}
            className={styles.modal}
        >
            <div
                className={styles.description}
                data-testid={testIds.appManager.main.db.modal}
            >
                <p>
                    {body}
                </p>
            </div>
            <HorizontalGroup justify='flex-end'>
                <Button
                    variant='secondary'
                    onClick={onDismiss}
                >
                    Cancel
                </Button>
                <Button
                    data-testid={testIds.appManager.main.db.confirm}
                    variant='destructive'
                    onClick={onConfirm}
                    className={styles.btnMarginTop}
                >
                    Confirm
                </Button>
            </HorizontalGroup>
        </Modal>
    )
}

const getStyles = (theme: GrafanaTheme2) => ({
    modal: css`
        margin-top: 10%;
        width: 35%;
    `,
    btnMarginTop: css`
        margin-top: 2px;
    `,
    description: css`
        font-size: 1.2em;
        margin-bottom: ${theme.spacing(2)};
    `
});
