// External
import React, { useState } from 'react';
import { Button } from '@grafana/ui';
import { css } from '@emotion/css';

// Internal
import { ACTIONS } from 'utils/utils.dbActions';
import { ConfirmModal } from '../ConfirmModal';
import { testIds } from 'components/testIds';

interface UBProps {
    rowState: any,
    executeOp: (opType: ACTIONS) => Promise<void>,
    busy: boolean
}

export function UninstallButton(props: UBProps) {
    const { rowState, executeOp, busy } = props;

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                data-testid={testIds.appManager.main.db.row.uninstall}
                className={style.btnMarginTop}
                disabled={rowState[ACTIONS.Uninstall] || busy}
                variant='destructive'
                onClick={() => { setConfirmOpen(true) }}>
                {rowState[ACTIONS.Uninstall] ? "Uninstalling..." : ACTIONS.Uninstall}
            </Button>
            {
                confirmOpen &&
                <ConfirmModal
                    isOpen={confirmOpen}
                    title={`Uninstall ${rowState.name}`}
                    body={`Are you sure you want to uninstall?
                        This will delete all instances of the dashboard.`}
                    onConfirm={async () => { await executeOp(ACTIONS.Uninstall) }}
                    onDismiss={() => { setConfirmOpen(false) }}
                />
            }
        </>
    );
}

const style = {
    btnMarginTop: css`
        margin-top: 2px;
    `
}
