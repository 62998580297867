// Imports
import { PLUGIN_ID, PLUGIN_MANIFEST } from "./utils.constants";
import { FetchError } from "./utils.errors";
import { PluginManifest } from "./utils.types";

// BASE URLs
export const PLUGIN_BASE_URL = '/plugins';
export const API_BASE_URL = '/api';
export const PUBLIC_BASE_URL = '/public';

// Grafana REST API endpoints
export const GET_ORGS_URL = `${API_BASE_URL}/orgs`;
export const GET_USER_ORGS_URL = `${API_BASE_URL}/user/orgs`;
export const GET_USER_TEAMS_URL = `${API_BASE_URL}/user/teams`;
export const GET_TEAMS_URL = `${API_BASE_URL}/teams/search`;
export const GET_PLUGINS_URL = `${API_BASE_URL}${PLUGIN_BASE_URL}`;
export const GET_DB_LIST_URL = `${API_BASE_URL}/search?type=dash-db`;
export const CREATE_ORG_URL = `${API_BASE_URL}/orgs`;
export const CREATE_TEAM_URL = `${API_BASE_URL}/teams`;
export const CREATE_FOLDER_URL = `${API_BASE_URL}/folders`;
export const TEAM_PERMISSIONS_URL = `${API_BASE_URL}/widesky/teamPluginPerms`;
export const IMPORT_DASHBOARD_URL = `${API_BASE_URL}/dashboards/import`;
export const GET_DASHBOARD_JSON_URL = (pluginId: string, path: string) => {
    return `${PUBLIC_BASE_URL}${PLUGIN_BASE_URL}/${pluginId}${path}`;
}
export const GET_DASHBOARD_URL = (uid: string) => {
    return `${API_BASE_URL}/dashboards/uid/${uid}`;
}
export const GET_FOLDER_URL = (uid: string) => {
    return `${API_BASE_URL}/folders/${uid}`;
}
export const DELETE_FOLDER_URL = (uid: string) => {
    return `${API_BASE_URL}/folders/${uid}`;
}
export const DELETE_TEAM_URL = (teamId: number) => {
    return `${API_BASE_URL}/teams/${teamId}`;
}
export const SWITCH_ORGS_URL = (orgId: number) => {
    return `${API_BASE_URL}/user/using/${orgId}`;
}
export const GET_PLUGIN_MANIFEST_URL = (pluginId: string) => {
    return `${PUBLIC_BASE_URL}${PLUGIN_BASE_URL}/${pluginId}/dashboards/${PLUGIN_MANIFEST}`;
}
export const GET_TEAM_PERMISSIONS_URL = (teamId: number) => {
    return TEAM_PERMISSIONS_URL + `?teamId=${teamId}`;
}

// Provisioner backend API endpoints
export const API_BACKEND_BASE_URL = `${API_BASE_URL}/plugins/${PLUGIN_ID}/resources`;
export const GET_LANGUAGES_URL = `${API_BACKEND_BASE_URL}/languages`;
export const GET_TEAM_DATA_URL = (teamName: string) => {
    return `${API_BACKEND_BASE_URL}/team/${teamName}/data`;
};
export const SET_TEAM_DATA_URL = (teamName: string) => {
    return `${API_BACKEND_BASE_URL}/team/${teamName}/writeData`;
}
export const GET_CONFIG_URL = `${API_BACKEND_BASE_URL}/config`;

/**
 * Switches the user's organisation context.
 * @param orgId the organisation ID.
 */
export const switchOrgContext = async (orgId: number) => {
    const url = SWITCH_ORGS_URL(orgId);
    const res = await fetch(url, { method: 'POST' });
    if (!res.ok) {
        throw new FetchError(res, url);
    }
}

/**
 * Fetches the manifest.json of the given plugin.
 * @param plugin the plugin
 * @returns the manifest
 */
export const fetchPluginManifest = async (pluginId: string): Promise<PluginManifest> => {
    const url = GET_PLUGIN_MANIFEST_URL(pluginId);
    const res = await fetch(url);
    if (!res.ok) {
        throw new FetchError(res, url);
    }
    const data = await res.json();
    return data;
}
