// External
import React, { useContext, useEffect, useState } from 'react';
import { InlineField, InlineSwitch } from '@grafana/ui';

// Internal
import { ManagerContext } from './ManagerContext';
import { testIds } from 'components/testIds';

interface PTProps {
    pluginId: string,
    provisioned: boolean
}

export function PluginToggle(props: PTProps) {
    const { provisioned, pluginId } = props;
    const [enabled, setEnabled] = useState<boolean>(false);

    const { formState, setFormState } = useContext(ManagerContext);
    const enabledApps = formState.plugins;

    useEffect(() => {
        // Set initial state
        setEnabled(provisioned);

        // Will only run once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Toggles switch UI and plugin form state.
     */
    const togglePlugin = () => {
        let appsArr

        if (enabled) {
            appsArr = enabledApps.filter(app => {
                return (app !== pluginId);
            });
        }
        else {
            appsArr = [...enabledApps, pluginId];
        }

        setFormState({
            ...formState,
            plugins: appsArr,
            changed: true
        });
        setEnabled(!enabled);
    }

    return (
        <InlineField>
            <InlineSwitch
                data-testid={`${testIds.appManager.main.form.plugin.toggle}-${pluginId}`}
                label='Enabled'
                showLabel
                value={enabled}
                onChange={togglePlugin} />
        </InlineField>
    )
}
