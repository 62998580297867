// External
import React, { useState } from 'react';

// Internal
import { Plugin } from 'utils/utils.types';
import { DashboardContainer } from './DashboardContainer';
import { AlertBox } from 'components/Common/AlertBox';

interface DMProps {
    plugin: Plugin,
    busy: boolean,
    setBusy: React.Dispatch<React.SetStateAction<boolean>>
};

export function DashboardManager(props: DMProps) {
    const { plugin, busy, setBusy } = props;

    const [dashboardError, setDashboardError] = useState<string | undefined>(undefined);

    return (
        <>
            {
                dashboardError
                    ?
                    <AlertBox
                        title='An error occurred...'
                        severity='error'
                        elevated={false}
                        message={dashboardError}
                    />
                    :
                    <DashboardContainer
                        plugin={plugin}
                        setDashboardError={setDashboardError}
                        busy={busy}
                        setBusy={setBusy}
                    />
            }
        </>
    );
}
