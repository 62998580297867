// External
import React, { useState } from 'react';
import { Button } from '@grafana/ui';

// Internal
import { ACTIONS } from 'utils/utils.dbActions';
import { ConfirmModal } from '../ConfirmModal';
import { testIds } from 'components/testIds';

interface UBProps {
    rowState: any,
    executeOp: (opType: ACTIONS) => Promise<void>,
    busy: boolean
}

export function UpgradeButton(props: UBProps) {
    const { rowState, executeOp, busy } = props;

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                data-testid={testIds.appManager.main.db.row.upgrade}
                disabled={rowState[ACTIONS.Upgrade] || busy}
                variant='success'
                onClick={() => { setConfirmOpen(true) }}>
                {rowState[ACTIONS.Upgrade] ? "Upgrading..." : ACTIONS.Upgrade}
            </Button>
            {
                confirmOpen &&
                <ConfirmModal
                    isOpen={confirmOpen}
                    title={`Upgrade ${rowState.name}`}
                    body={`Are you sure you want to upgrade?
                        This will overwrite all user changes
                        to the dashboard.`}
                    onConfirm={async () => { await executeOp(ACTIONS.Upgrade) }}
                    onDismiss={() => setConfirmOpen(false)}
                />
            }
        </>
    );
}
