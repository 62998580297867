// External
import React, { useContext } from 'react';

// Internal
import { LanguageSelector } from './LanguageSelector';
import { SubmitButton } from './SubmitButton';
import { PluginContainer } from './PluginContainer';
import { ManagerContext } from './ManagerContext';
import { testIds } from 'components/testIds';

export function FormContainer() {
    const { formState } = useContext(ManagerContext);
    const { changed } = formState;

    return (
        <div data-testid={testIds.appManager.main.form.container}>
            <LanguageSelector />
            <PluginContainer />
            {changed && <SubmitButton />}
        </div>
    );
}
