// External
import React from 'react';
import { VerticalGroup, Alert } from '@grafana/ui';

// Internal
import { testIds } from 'components/testIds';

interface PluginProps {
    severity: "error" | "warning" | "success" | "info",
    title: string,
    elevated: boolean,
    message: string
};

export function AlertBox(props: PluginProps) {
    return (
            <Alert data-testid={testIds.appManager.main.alert} severity={props.severity} title={props.title} elevated={props.elevated}>
                <VerticalGroup>
                    <div>{props.message}</div>
                </VerticalGroup>
            </Alert>
    );
}
