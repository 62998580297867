// External
import { Field, MultiSelect } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';

// Internal
import { ManagerContext } from './ManagerContext';
import { testIds } from 'components/testIds';
import { Language } from 'utils/utils.types';

export function LanguageSelector() {
    const [languages, setLanguages] = useState<any[]>([]);
    const [selected, setSelected] = useState<any[]>([]);

    const { dataState, formState, setFormState } = useContext(ManagerContext);
    const team = formState.team;
    const teamName = team?.name;
    const available = dataState.languages;

    useEffect(() => {

        // Set available languages
        setLanguages(available.map(lang => {
            return {
                label: lang.full,
                value: lang.BCP47
            };
        }));

        // Set selected languages
        if (team) {
            setSelected(team.orgs.map(o => {
                return {
                    label: o.language.full,
                    value: o.language.BCP47
                };
            }));
        }
        // Will only run on mount or when team changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamName]);

    /**
     * Handles changes in the select component.
     * @param values the selected languages.
     */
    const handleChange = (values: any) => {
        const languages = values.map((v: any) => {
            const relevantLang = available.find((lang: Language) => lang.BCP47 === v.value);
            const orgAlias = relevantLang ? relevantLang.orgAlias : '';

            return {
                BCP47: v.value,
                full: v.label,
                orgAlias,
            };
        });
        setFormState({
            ...formState,
            languages: languages,
            changed: true
        });
    }

    return (
        <>
            <Field label="Languages:" data-testid={testIds.appManager.main.form.language}>
                <MultiSelect
                    options={languages}
                    value={selected}
                    onChange={(v) => {
                        setSelected(v);
                        handleChange(v);
                    }}
                />
            </Field>
        </>
    )

}
