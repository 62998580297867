// External
import React, { useState } from 'react';
import { Card, InlineFieldRow } from '@grafana/ui';

// Internal
import { Plugin } from 'utils/utils.types';
import { PluginToggle } from './PluginToggle';
import { DashboardButton } from './DashboardManager/DashboardButton';
import { DashboardManager } from './DashboardManager/DashboardManager';

interface PRProps {
    plugin: Plugin,
    provisioned: boolean,
    busy: boolean,
    setBusy: React.Dispatch<React.SetStateAction<boolean>>
}

export function PluginRow(props: PRProps) {
    const { plugin, provisioned, busy, setBusy } = props;

    const [dbOpen, setDbOpen] = useState<boolean>(false);

    return (
        <>
            <Card>
                <Card.Heading disabled>{plugin.name}</Card.Heading>
                <Card.Meta>{[plugin.author, plugin.version, plugin.updated]}</Card.Meta>
                <Card.Tags>
                    <InlineFieldRow>
                        <DashboardButton open={dbOpen} setOpen={setDbOpen} />
                        <PluginToggle provisioned={provisioned} pluginId={plugin.id} />
                    </InlineFieldRow>
                </Card.Tags>
            </Card>
            {dbOpen && <DashboardManager plugin={plugin} busy={busy} setBusy={setBusy} />}
        </>
    );
}
