// External
import { NavModelItem } from '@grafana/data';

// Internal
import pluginJson from '../plugin.json';

export const NAVIGATION_TITLE = 'WideSky Grafana Provisioner';
export const NAVIGATION_SUBTITLE = 'Provision WideSky application plugins.';
export const PLUGIN_ID = 'widesky-grafana-provisioner';
export const PLUGIN_PAGE_URL = `/a/${pluginJson.id}`;
export const PLUGIN_MANIFEST = `manifest.json`;
export const ADMIN_ORG_ID = 1;
export const DEFAULT_LANGUAGE = {
  BCP47: 'en-US',
  full: 'English (US)'
};
export const DEFAULT_LANGUAGE_ORG_ALIAS = '';

export enum ROUTES {
  AppManager = 'app-manager',
  Two = 'two',
};

export const NAVIGATION: Record<string, NavModelItem> = {
  [ROUTES.AppManager]: {
    id: ROUTES.AppManager,
    text: 'App Manager',
    icon: 'apps',
    url: `${PLUGIN_PAGE_URL}/app-manager`,
  }
};
