// External
import React, { useEffect, useState } from 'react';
import { Card, HorizontalGroup } from '@grafana/ui';

// Internal
import { ADMIN_ORG_ID, DEFAULT_LANGUAGE } from 'utils/utils.constants';
import { ACTIONS, FuncMap } from 'utils/utils.dbActions';
import { Plugin } from 'utils/utils.types';
import { switchOrgContext } from 'utils/utils.endpoints';
import { InstallButton } from './Buttons/InstallButton';
import { UninstallButton } from './Buttons/UninstallButton';
import { UpgradeButton } from './Buttons/UpgradeButton';
import { DowngradeButton } from './Buttons/DowngradeButton';
import { testIds } from 'components/testIds';

interface DRProps {
    plugin: Plugin,
    dashboard: any,
    sorted: any[],
    setSorted: React.Dispatch<React.SetStateAction<any[]>>,
    setDashboardError: React.Dispatch<React.SetStateAction<string | undefined>>,
    busy: boolean,
    setBusy: React.Dispatch<React.SetStateAction<boolean>>
}

export function DashboardRow(props: DRProps) {
    const { plugin, dashboard, sorted, setSorted, setDashboardError, busy, setBusy } = props;

    const [rowState, setRowState] = useState<any>({
        name: "",
        [ACTIONS.Install]: false,
        [ACTIONS.Upgrade]: false,
        [ACTIONS.Downgrade]: false,
        [ACTIONS.Uninstall]: false,
    });

    useEffect(() => {
        let defaultDb = dashboard.variants.find((v: any) => v.language === DEFAULT_LANGUAGE.BCP47);
        if (defaultDb) {
            setRowState({
                ...rowState,
                name: defaultDb.name,
            });
        } else {
            setRowState({
                ...rowState,
                name: dashboard.variants[0].name,
            });
        }

        // Will only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Executes the given operation.
     * @param opType The type of operation
     */
    const executeOp = async (opType: ACTIONS) => {
        try {
            setBusy(true);
            // Set loading
            setRowState({
                ...rowState,
                [opType]: true
            });

            // For each dashboard in variants
            const newVariants = [];
            for (const idx in dashboard.variants) {
                const db = dashboard.variants[idx];

                if (db.actions.includes(opType)) {
                    // Execute operation
                    const variant = await FuncMap[opType]({ ...db, uid: dashboard.uid }, plugin);
                    newVariants.push(variant);
                }
            }

            // Return to admin context after finish
            await switchOrgContext(ADMIN_ORG_ID);

            // Replace with updated actions and folders
            const idx = sorted.findIndex((db: any) => db.uid === dashboard.uid);
            const newSorted = [...sorted];
            newSorted[idx] = {
                ...dashboard,
                variants: newVariants,
                actions: dashboard.actions.filter((a: string) => a !== opType)
            };

            // Push new available action
            if (opType === ACTIONS.Install) {
                newSorted[idx].actions.push(ACTIONS.Uninstall);
            }
            // Only install available on uninstall
            else if (opType === ACTIONS.Uninstall) {
                newSorted[idx].actions = [ACTIONS.Install];
            }

            // Set state
            setSorted(newSorted);
            setBusy(false);
            setRowState({
                ...rowState,
                [opType]: false
            });
        } catch (err: any) {
            setDashboardError(err.message);
        }
    }

    return (
        <Card data-testid={testIds.appManager.main.db.row.card}>
            <Card.Heading>
                {rowState.name}
            </Card.Heading>
            <Card.Meta>{[`UID: ${dashboard.uid}`, `version: ${dashboard.version}`]}</Card.Meta>
            <Card.Tags>
                <HorizontalGroup>
                    {
                        dashboard.actions.includes(ACTIONS.Install) &&
                        <InstallButton busy={busy} rowState={rowState} executeOp={executeOp} />
                    }
                    {
                        dashboard.actions.includes(ACTIONS.Upgrade) &&
                        <UpgradeButton busy={busy} rowState={rowState} executeOp={executeOp} />
                    }
                    {
                        dashboard.actions.includes(ACTIONS.Downgrade) &&
                        <DowngradeButton busy={busy} rowState={rowState} executeOp={executeOp} />
                    }
                    {
                        dashboard.actions.includes(ACTIONS.Uninstall) &&
                        <UninstallButton busy={busy} rowState={rowState} executeOp={executeOp} />
                    }
                </HorizontalGroup>
            </Card.Tags>
        </Card>
    );
}
