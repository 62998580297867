// External
import React, { useState } from 'react';

// Internal
import { testIds } from 'components/testIds';
import { DataState, EmptyDataState, EmptyFormState, FormState } from 'utils/utils.types';
import { AlertBox } from 'components/Common/AlertBox';
import { ManagerContext } from './ManagerContext';
import { TeamSelector } from './TeamSelector';
import { FormContainer } from './FormContainer';

export function AppManager() {
  const [dataState, setDataState] = useState<DataState>(EmptyDataState);
  const [formState, setFormState] = useState<FormState>(EmptyFormState);
  const [formErr, setFormErr] = useState<string | undefined>(undefined);

  return (
    <ManagerContext.Provider value={{
      dataState,
      setDataState,
      formState,
      setFormState,
      formErr,
      setFormErr
    }}>
      <h2>App Manager</h2>
      <div data-testid={testIds.appManager.main.container}>
        {formErr
          ?
          <AlertBox title='An error occurred...' severity='error' elevated={false} message={formErr} />
          :
          <>
            <TeamSelector />
            {formState.team && <FormContainer />}
          </>}
      </div>

    </ManagerContext.Provider>
  );
}
