export class FetchError extends Error {
    constructor(res: any, url: string) {
        super();
        if (res.status && res.statusText) {
            this.message = `${res.status}: ${res.statusText} @ ${url}`;
        }
        else if (res.status) {
            this.message = `Error occurred, error code: ${res.status} @ ${url}`;
        }
        else if (res.statusText) {
            this.message = `Error occurred, error: ${res.statusText} @ ${url}`;
        }
        else {
            this.message = `Error occurred, please try again ${url ? '@ ' + url: ''}`;
        }
        Object.setPrototypeOf(this, FetchError.prototype);
    }
}
